// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/usr/local/var/www/vhosts/bilderbuch-passport/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("/usr/local/var/www/vhosts/bilderbuch-passport/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/usr/local/var/www/vhosts/bilderbuch-passport/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/usr/local/var/www/vhosts/bilderbuch-passport/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/usr/local/var/www/vhosts/bilderbuch-passport/.cache/data.json")

